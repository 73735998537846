.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: "primary";
  src: url(./assets/fonts/Helvetica-Ultra-Compressed.woff);
}

@font-face {
  font-family: "secondary";
  src: url(./assets/fonts/GothamMedium.woff);
}

*{font-family: 'primary' !important ; color: #fff;}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTypography-h1{
  font-size: 110px !important;
  font-family: 'secondary' !important;
  text-transform: uppercase;
}

.MuiTypography-h2{
  font-size: 80px !important;
  font-family: 'secondary' !important;
  text-transform: uppercase;
}

.MuiTypography-h6{
  font-size: 35px !important;
  text-transform: uppercase;
}
.MuiTypography-body1{
  font-size: 24px !important;
  font-family: 'arial' !important;
  color: #AAAAAA !important;
}
.MuiTypography-body2{
  font-size: 18px !important;
  font-family: 'arial' !important;
  color: #AAAAAA !important;
}

@media screen and (max-width: 1100px) {
  .MuiTypography-h1{
    font-size: 90px !important;
  }
  
  .MuiTypography-h2{
    font-size: 65px !important;
  }
}

@media screen and (max-width: 600px) {
  .MuiTypography-h1{
    font-size: 60px !important;
  }
  
  .MuiTypography-h2{
    font-size: 40px !important;
  }
}

.galleryImage {
  border-radius: 5px;
  transition: transform 0.2s;
}

.galleryImage:hover {
  transform: scale(1.05);
}